// custom typefaces
import "typeface-bitter"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

//for react date picker
import "react-datepicker/dist/react-datepicker.css";

import React from 'react';
import NetlifyIdentityContext from './src/identity';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const wrapRootElement = ({ element }) => {
  return (
    <NetlifyIdentityContext url={`https://app.upsideanalytics.io`}>
      <GoogleOAuthProvider clientId={'189818773131-36honofu0hd0oe66jtojoe2mgb8brio2.apps.googleusercontent.com'}>
        {element}
      </GoogleOAuthProvider>
    </NetlifyIdentityContext>
  )
}
